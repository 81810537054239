import React from 'react';
import DeliveryAPIError from '../../../../components/contentstack/DeliveryAPIError';
import ContentstackRenderer from '../../../../components/contentstack/renderer';
import ErrorPage from 'components/contentstack/ErrorPage';

const ContentstackContentListTastic = ({ data }: any) => {
  const content = (data?.data?.dataSource ?? []) as any;

  if (!content) {
    return <ErrorPage />;
  } else if (content?.err) {
    return <DeliveryAPIError data={content.err} />;
  } else {
    return content.map((item: any, index: number) => (
      <ContentstackRenderer data={item.data} contentTypeUid={item.contentTypeUid} key={index} />
    ));
  }
};

export default ContentstackContentListTastic;
